import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

const SignIn = Loadable(lazy(() => import('app/views/SignIn')));

const CountryList = Loadable(lazy(() => import('app/views/country/List')));
const CountryForm = Loadable(lazy(() => import('app/views/country/Form')));

const GrapesList = Loadable(lazy(() => import('app/views/grapes/List')));
const GrapesForm = Loadable(lazy(() => import('app/views/grapes/Form')));

const WineryList = Loadable(lazy(() => import('app/views/winery/List')));
const WineryForm = Loadable(lazy(() => import('app/views/winery/Form')));

const WineList = Loadable(lazy(() => import('app/views/wine/List')));
const WineForm = Loadable(lazy(() => import('app/views/wine/Form')));

const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const CellarList = Loadable(lazy(() => import('app/views/cellar/List')));
const CellarForm = Loadable(lazy(() => import('app/views/cellar/Form')));

const UserList = Loadable(lazy(() => import('app/views/user/List')));

const DeliveryRequestList = Loadable(lazy(() => import('app/views/delivery/List')));
const DeliveryRequestForm = Loadable(lazy(() => import('app/views/delivery/Form')));

const WarehousedRequestList = Loadable(lazy(() => import('app/views/warehousedRequest/List')));
const WarehousedRequestForm = Loadable(lazy(() => import('app/views/warehousedRequest/Form')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      },
      { path: '/wine/list', element: <WineList /> },
      { path: '/wine/form/:queryWineId', element: <WineForm /> },
      { path: '/winery/list', element: <WineryList /> },
      { path: '/winery/form/:queryWineryId', element: <WineryForm /> },
      { path: '/country/list', element: <CountryList /> },
      { path: '/country/form/:queryCountryId', element: <CountryForm /> },
      { path: '/grapes/list', element: <GrapesList /> },
      { path: '/grapes/form/:queryGrapesId', element: <GrapesForm /> },
      { path: '/cellar/list', element: <CellarList /> },
      { path: '/cellar/form/:queryUserId', element: <CellarForm /> },
      { path: '/user/list', element: <UserList /> },
      { path: '/delivery/list', element: <DeliveryRequestList /> },
      { path: '/delivery/form/:queryDeliveryId', element: <DeliveryRequestForm /> },
      { path: '/warehoused/requests/list', element: <WarehousedRequestList /> },
      { path: '/warehoused/requests/form/:queryWarehousedId', element: <WarehousedRequestForm /> },
    ]
  },

  { path: '/', element: <SignIn /> },
  { path: '*', element: <Navigate to="dashboard/default" /> },
];

export default routes;
